import React from 'react';
import PropTypes from 'prop-types';
// import { Form, Input, Button } from 'reactstrap';
import { fetch_ } from '../utils';
import './styles.css';


export default class Login extends React.Component {
  static displayName = 'Login';

  static propTypes = {
    history: PropTypes.object.isRequired,
    onAuth: PropTypes.func.isRequired,
  };

  state = {
    email: '',
  };

  componentDidMount() {
    const auth = localStorage.getItem('auth');
    const { onAuth, history } = this.props;
    onAuth(auth);
    if (auth) {
      history.push('/games');
    } else {
      // FIXME this isn't applying focus for some reason.
      // The input is what I expect, but...
      setTimeout(() => this.inputRef.focus(), 1);
    }
  }

  assignInputRef = (r) => { this.inputRef = r; };

  changeInput = (e) => {
    this.setState({ email: e.target.value });
  };

  submit = (e) => {
    if (e) { e.preventDefault(); }
    const { email } = this.state;
    const { onAuth, history } = this.props;

    localStorage.setItem('auth', email);
    fetch_('auth', {
      method: 'POST',
      body: JSON.stringify({ email }),
    }).then(() => {
      onAuth(true);
      // localStorage.setItem('auth', res.userId)
      history.push('/games');
    });
    return false;
  };

  render() {
    const { email } = this.state;
    return (
      <form onSubmit={this.submit}>
        <input
          type="text"
          id="login-input"
          value={email}
          ref={this.assignInputRef}
          placeholder="Enter email address"
          onChange={this.changeInput}
        />
        <button
          type="submit"
          id="login-btn"
          onClick={this.submit}
        >
          Submit
        </button>
      </form>
    );
  }
}
