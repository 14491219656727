import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { fetch_ } from '../../utils';
import './styles.css';

export default class Game extends React.Component {
  static displayName = 'Game';

  static propTypes = {
    history: PropTypes.object.isRequired, // eslint-disable-line
  };

  // gameId for this browsing session. TODO use react-router to pick up prior sessions
  gameId = null;

  state = {
    // state,messages come from server
    // state: null,
    messages: [],
    done: false,
  };

  componentDidMount() {
    document.body.addEventListener('click', this.ensureFocus);

    this.gameId = this.props.match.params.id;   // eslint-disable-line
    fetch_(`game/${this.gameId}`).then(this.handleResponse);
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.ensureFocus);
  }

  // Clear the input and give it focus
  clearInput = () => {
    const { messages: m } = this.state;
    if (/YOU SCORED [0-9]+ OUT OF/im.test(m[m.length - 1])) {
      return this.setState({ done: true });
    }

    this.inputRef.value = '';
    return setTimeout(this.ensureFocus, 0);
  };

  ensureFocus = (e) => {
    // Check first if they're selecting text, don't force focus
    // See https://stackoverflow.com/questions/10390010/jquery-click-is-triggering-when-selecting-highlighting-text
    if (window.getSelection().toString()) { return; }

    window.scrollTo(0, document.body.scrollHeight);

    const { done } = this.state;
    if (done) {
      // pass through so they can click "back to games"
      return;
    }
    this.inputRef.focus();
  };

  // Set state from server responses
  // TODO add error-handling
  handleResponse = (res) => {
    this.setState({
      // state: res.state,
      messages: res.messages,
    }, this.clearInput);
  };

  // Just grabs a handle to <input /> so we can muck with it later
  assignInputRef = (r) => { this.inputRef = r; };

  submit = (e) => {
    e.preventDefault();
    const command = this.inputRef.value;
    // Send command, handle response
    fetch_(`game/${this.gameId}`, {
      method: 'POST',
      body: JSON.stringify({ command }),
    }).then(this.handleResponse);
    return false;
  };

  renderMessage = (message, i) =>
    <div className={`message persona-${message['persona']}`} key={i}>{message['text']}</div>;

  render() {
    const { messages, done } = this.state;

    return (
      <div>
        <div className="messages-container">
          <Link to="/games">&laquo;Back</Link>
          <div>{messages.map(this.renderMessage)}</div>
          {done ? (
            <div className="back-to-games">
              <Link to="/games">Back to My Games</Link>
            </div>
          ) : (
            <form
              className="main-input"
              onSubmit={this.submit}
            >
              <table>
                <tbody>
                  <tr>
                    <td className="prompt">
                      $&gt;&nbsp;
                    </td>
                    <td>
                      <input
                        className="command-entry"
                        type="text"
                        placeholder="enter command.."
                        ref={this.assignInputRef}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </form>
          )}
        </div>
      </div>
    );
  }
}
