import React from 'react';
import { Route, Link } from 'react-router-dom';
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import './styles.css';

import Login from '../Login';
import Games from '../Game/Games';
import Game from '../Game/Game';
import Webcam from '../Webcam';

export default class App extends React.Component {
  static displayName = 'App';

  state = {
    loggedIn: !!localStorage.getItem('auth'),
  };

  logout = () => {
    localStorage.removeItem('auth');
    window.location = '/';
  };

  onAuth = loggedIn => this.setState({ loggedIn });

  renderNav = () => {
    const { loggedIn } = this.state;
    return (
      <Navbar expand="md">
        <NavbarBrand href="/">Home</NavbarBrand>
        <Nav className="ml-auto" navbar>
          {loggedIn && (
            <NavItem>
              <NavLink tag={Link} to="/games">My Games</NavLink>
            </NavItem>
          )}
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret>
              Experiments
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem>
                <NavLink tag={Link} to="/webcam">Webcam</NavLink>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          {loggedIn ? (
            <NavItem>
              <NavLink href="#" onClick={this.logout}>Logout</NavLink>
            </NavItem>
          ) : (
            <NavItem>
              <NavLink tag={Link} to="/">Login</NavLink>
            </NavItem>
          )}
        </Nav>
      </Navbar>
    );
  };

  render() {
    return (
      <div>
        {this.renderNav()}
        <div className="container-fluid">
          <Route
            path="/"
            exact
            render={props => <Login {...props} onAuth={this.onAuth} />}
          />
          <Route path="/games" component={Games} />
          <Route path="/game/:id" component={Game} />
          <Route path="/webcam" component={Webcam} />
        </div>
      </div>
    );
  }
}
