// create-react-app env variables, see https://facebook.github.io/create-react-app/docs/adding-custom-environment-variables
const URL = {
  'development': 'http://localhost:5000',
  'test': 'http://localhost:5000',
  'production': 'https://backend.escapethecave.ai'
}[process.env.NODE_ENV]

const fetch_ = async (route, overrides={}) => {
  let auth = localStorage.getItem('auth')
  auth = auth ? {'X-Auth-Id': auth} : {}
  const res = await fetch(`${URL}/${route}`, {
    // Specify these headers to work with Flask's request.get_json()
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      ...auth
    },
    ...overrides
  })
  if (!res.ok) {throw res}
  return await res.json()
}

export { URL, fetch_ }
