/* eslint react/jsx-one-expression-per-line: 0, no-continue: 0, no-await-in-loop: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import ReactWebcam from 'react-webcam';
import _ from 'lodash';
import { FormGroup, Label, Input } from 'reactstrap';
// import * as tf from '@tensorflow/tfjs';
import * as mobilenet from '@tensorflow-models/mobilenet';
import * as cocoSsd from '@tensorflow-models/coco-ssd';
import yolo from 'tfjs-yolo';

import * as posenet from '@tensorflow-models/posenet';
import {
  drawBoundingBox,
  drawKeypoints,
  drawSkeleton,
  renderImageToCanvas,
} from './demo_util';

import './styles.css';

// Sample code at https://codelabs.developers.google.com/codelabs/tensorflowjs-teachablemachine-codelab/index.html#5
export default class Webcam extends React.Component {
  static displayName = 'Webcam';

  static propTypes = {
    history: PropTypes.object.isRequired, // eslint-disable-line
  };

  models = {
    mobilenetv2: {
      model: mobilenet,
      args: [2], // version 2
      name: 'MobileNet v2',
      dims: [224, 224],
      type: 'classify',
    },
    cocossd: {
      model: cocoSsd,
      name: 'COCO SSD',
      dims: [600, 500],
      type: 'detect',
    },
    posenet: {
      model: posenet,
      name: 'PoseNet',
      dims: [600, 500],
      type: 'estimatePoses',
      // args: https://github.com/tensorflow/tfjs-models/tree/master/posenet
    },
    yolo: {
      model: { load: yolo.v3tiny },
      args: [],
      name: 'Tiny YOLOv3',
      dims: [416, 416],
      type: 'detect',
    },
  };

  webcamDims = [1280, 720];

  state = {
    classifications: [],
    model: 'mobilenetv2',
  };

  componentDidMount() {
    this.loadModel();
  }

  componentWillUnmount() {
    this.unloading = true;
    // TODO model.unload()?
    clearTimeout(this.timeout);
  }

  loadModel = async () => {
    const { model } = this.state;
    const m = this.models[model];
    console.log(`Loading ${model}..`);
    this.net = await m.model.load(...(m.args || []));
    console.log('Sucessfully loaded model');
  };

  runAgain = () => { this.timeout = setTimeout(this.runModel, 1000); };

  runModel = async () => {
    const { model } = this.state;
    if (this.unloading) { return this.runAgain(); }
    if (!this.net) { return this.runAgain(); }
    const img = this.getImage();
    if (!img) { return this.runAgain(); }

    const m = this.models[model];
    const ctx = this.canvas.getContext('2d');
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    await this[m.type](img); // run task

    // Give some breathing room by waiting for the next animation frame to fire.
    // await tf.nextFrame();
    this.runAgain();
    return null;
  };

  classify = async (img) => {
    const result = await this.net.classify(img);
    this.lastDetection = result; // useful for tests

    this.setState({
      classifications: result,
    });
  };

  detect = async (img) => {
    const { canvas } = this;
    const { model } = this.state;
    const m = this.models[model];

    const ctx = canvas.getContext('2d');
    const [modelW, modelH] = m.dims;
    const [vidW, vidH] = this.webcamDims;
    const [wRatio, hRatio] = [vidW / modelW, vidH / modelH];

    let predictions;
    if (model === 'yolo') {
      // https://github.com/shaqian/tfjs-yolo#run-model
      const opts = {
        scoreThreshold: 0.2,
      };
      predictions = await this.net.predict(img, opts);
    } else {
      predictions = await this.net.detect(img);
    }
    predictions.forEach((p) => {
      let left, top, width, height; // eslint-disable-line
      if (model === 'yolo') {
        // https://github.com/shaqian/tfjs-yolo#output-box-format
        [left, top, width, height] = [p.left, p.top, p.width, p.height];
      } else {
        [left, top, width, height] = p.bbox;
      }
      left = Math.floor(left * wRatio);
      top = Math.floor(top * hRatio);
      width = Math.floor(width * wRatio);
      height = Math.floor(height * hRatio);

      // Rectangles
      ctx.fillStyle = ctx.strokeStyle = 'white'; // eslint-disable-line
      ctx.strokeRect(left, top, width, height);
      ctx.font = '16px Arial';
      ctx.fillText(p.class, left + 10, top);
    });
  };

  estimatePoses = async (img) => {
    const { canvas } = this;
    const { model } = this.state;
    const m = this.models[model];

    const ctx = canvas.getContext('2d');
    const [modelW, modelH] = m.dims;
    const [vidW, vidH] = this.webcamDims;
    const [wRatio, hRatio] = [vidW / modelW, vidH / modelH];
    const poses = await this.net.estimatePoses(img, {
      flipHorizontal: false,
      decodingMethod: 'single-person',
    });

    const minPoseConfidence = 0.1;
    const minPartConfidence = 0.5;
    // renderImageToCanvas(image, [513, 513], canvas);
    poses.forEach((pose) => {
      if (pose.score < minPoseConfidence) { return; }
      pose.keypoints.forEach((k) => {
        k.position.x *= wRatio; //eslint-disable-line
        k.position.y *= hRatio; //eslint-disable-line
      });
      drawKeypoints(pose.keypoints, minPartConfidence, ctx);
      drawSkeleton(pose.keypoints, minPartConfidence, ctx);
      // drawBoundingBox(pose.keypoints, ctx);
    });
  };

  // Overriding ReactWebcam.getCanvas(), because that function resizes the destination image to
  // <video>{width,height} I thought it was supposed to output to videoConstraints{width,height}?
  // FIXME figure this out, remove this code
  getImage = () => {
    const { model } = this.state;
    const [width, height] = this.models[model].dims;

    const el = this.webcamElement;
    const { state, props } = el;

    if (!state.hasUserMedia || !el.video.videoHeight) return null;

    const canvas = this.createCanvas(width, height);
    const ctx = canvas.getContext('2d');
    ctx.imageSmoothingEnabled = props.imageSmoothing;
    ctx.drawImage(el.video, 0, 0, canvas.width, canvas.height);

    return canvas;
  };

  // Function that just calls document.createElement, but that method's
  // not available during jest tests, so we need to override it there
  createCanvas = (width, height) => {
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    return canvas;
  };

  assignWebcam = (r) => { this.webcamElement = r; };

  assignCanvas = (r) => { this.canvas = r; };

  onSelectModel = (e) => {
    this.unloading = true;
    const state = {
      model: e.target.value,
      classifications: [],
    };
    this.setState(state, async () => {
      await this.loadModel();
      this.unloading = false;
    });
  };

  render() {
    const { classifications, model } = this.state;
    console.log(classifications);
    const [width, height] = this.webcamDims;
    // const [width, height] = this.models[model].dims;
    // const videoConstraints = { width, height, facingMode: 'user' };
    return (
      <div>
        <canvas
          ref={this.assignCanvas}
          width={width}
          height={height}
          style={{ position: 'absolute' }}
        />
        <ReactWebcam
          audio={false}
          width={width}
          height={height}
          ref={this.assignWebcam}
          screenshotFormat="image/jpeg"
          onUserMedia={this.runModel}
        />
        <FormGroup>
          <Label for="modelSelect">Model</Label>
          <Input
            type="select"
            name="modelSelect"
            onChange={this.onSelectModel}
            value={model}
          >
            {_.map(this.models, (m, k) => (
              <option value={k} key={k}>{m.name}</option>
            ))}
          </Input>
        </FormGroup>
        <ul className="list-unstyled">
          {classifications.slice(0, 5).map(c => (
            <li>{c.probability.toFixed(2) * 100 }%: {c.className}</li>
          ))}
        </ul>
      </div>
    );
  }
}
