import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { fetch_ } from '../../utils';
import './styles.css';

export default class Games extends React.Component {
  static displayName = 'Games';

  static propTypes = {
    history: PropTypes.object.isRequired,
  };

  state = {
    games: [],
  };

  componentDidMount() {
    fetch_('game').then((res) => {
      this.setState({ games: res.games });
    });
  }

  newGame = () => {
    fetch_('game', {
      method: 'POST',
    }).then((res) => {
      // Set this browsing-session's gameId
      const { history } = this.props;
      history.push(`/game/${res.id}`);
    });
  };

  renderList = () => {
    const { games } = this.state;
    if (!games || !games.length) {
      return <div>No games, click &quot;New Game&quot; to start a new one</div>;
    }
    return (
      <div className="my-games">
        My Games
        <ul>
          {games.map(g => (
            <li>
              <Link to={`/game/${g.id}`}>{g.id.split('-')[0]}</Link>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  render() {
    return (
      <div>
        <div>
          {this.renderList()}
        </div>
        <div>
          <button type="button" id="newgame-btn" onClick={this.newGame}>New Game</button>
        </div>
      </div>
    );
  }
}
